<template>
    <div class="zc_dialog_box">
        <el-dialog title="AI绘画" :visible.sync="dialogVisible" width="762px" :close-on-click-modal=false>
            <div class="main">
                <div class="main-l">
                    <el-carousel height="260px" :autoplay="false" indicator-position="none" arrow="always"
                        @change="changeImg">
                        <el-carousel-item v-for="item, index in info.production_file" :key="index">
                            <div class="img">
                                <img :src="item" alt="">
                                <!-- <el-image style="width: 260px; height: auto" :src="item" :preview-src-list="srcList"> -->
                                <!-- </el-image> -->
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                    <div class="indicator">{{ current }}/{{ info.production_file && info.production_file.length }}</div>
                </div>
                <div class="main-r">
                    <div class="main-r-c">
                        <div class="main-t">
                            <p>绘画灵感：</p>
                            <p class="inspiration">
                                {{ info.originality }}
                            </p>
                            <div class="style">
                                <div class="style-tit">风格：</div>
                                <div class="style-list">
                                    <div class="style-item" v-for="item in info.style" :key="item">{{ item }}</div>
                                </div>
                            </div>
                            <div class="artist">
                                <div class="artist-tit">艺术家：</div>
                                <div class="artist-item">{{ info.artist }}</div>
                            </div>
                            <div class="li">
                                <div class="li-l">画面尺寸：</div>
                                <div class="li-r">{{ info.proportion }}</div>
                            </div>
                            <div class="li">
                                <div class="li-l">创建时间：</div>
                                <div class="li-r">{{ info.create_time }}</div>
                            </div>
                        </div>
                        <el-button class="btn" size="small" type="primary" @click="downloadFile(info.production_file, info.name)"><i class="el-icon-download"></i> 下载绘画</el-button>
                    </div>
                    <div class="btn-box">
                        <!-- 我的绘画 点击回到列表页 -->
                        <el-button size="small" type="primary" @click="toList">我的绘画</el-button>
                        <!-- 重新生成 点击关闭当前弹窗，页面数据不清空重走创建逻辑 -->
                        <el-button size="small" type="primary" @click="reCreate">重新生成</el-button>
                        <!--新增绘画 点击关闭弹窗，创建页面重置 -->
                        <el-button size="small" type="primary" @click="resetPage">新增绘画</el-button>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {

    },
    data() {
        return {
            dialogVisible: false,
            current: 1,
            imgList: [
                require('@/assets/images/workben/box1.png'),
                require('@/assets/images/workben/box2.png'),
                require('@/assets/images/workben/box1.png'),
                require('@/assets/images/workben/box2.png'),
            ],
            srcList: [
                require('@/assets/images/workben/box1.png'),
                require('@/assets/images/workben/box2.png'),
                require('@/assets/images/workben/box1.png'),
                require('@/assets/images/workben/box2.png'),
            ],
            info: {}
        };
    },
    computed: {
    },
    created() {

    },
    methods: {
        open(info) {
            this.info = info
            this.dialogVisible = true
        },
        changeImg(val) {
            this.current = val + 1
        },
        // 回到列表页
        toList() {
            this.$router.push({
                path: '/creation/drawWorks',
                query: {
                    type: 1
                }
            })
        },
        // 重新生成
        reCreate() {
            this.dialogVisible = false
            this.$emit('reCreate')
        },
        // 新增 重置页面
        resetPage() {
            this.dialogVisible = false
            this.$emit('resetPage')
        },
        // 下载 多张
        downloadFile(val, name) {
            if (val && val.length) {
                for (let i = 0; i < val.length; i++) {
                    setTimeout(() => {
                        this.downloadMany(val[i], `${name}${Date.now()}`)
                    }, i * 1000)

                }
            }
        },
        downloadMany(url, name) {
            const a = document.createElement('a');
            // 解决图片跨域
            a.href = `${url}?response-content-type=application/octet-stream`;
            a.download = name || '';
            a.click();
            a.remove();
        },
    },
};
</script>

<style scoped lang="scss">
.main {
    width: 100%;
    height: 422px;
    display: flex;

    .main-l {
        width: 422px;
        height: 412px;
        background: #f8f8f8;
        border-radius: 10px;
        padding-top: 76px;
        position: relative;
        margin-right: 20px;

        .img {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 260px;
                height: auto;
            }
        }

        .indicator {
            position: absolute;
            width: 48px;
            height: 20px;
            background: rgba(0, 0, 0, 0.08);
            border-radius: 10px;
            font-size: 12px;
            color: #666666;
            line-height: 20px;
            text-align: center;
            left: 187px;
            bottom: 16px;
        }
    }

    .main-r {
        width: 280px;
        height: 422px;

        .main-r-c {
            width: 280px;
            height: 360px;
            background: #f8f8f8;
            border-radius: 10px;
            padding: 14px 0 14px 14px;

            .main-t {
                width: 100%;
                height: 280px;
                font-size: 12px;
                color: #666666;
                line-height: 17px;
                overflow-y: auto;

                .inspiration {
                    color: #333333;
                    padding-top: 6px;
                    width: 253px;
                }

                .style {
                    display: flex;
                    margin-top: 14px;

                    .style-tit {
                        width: 40px;
                        line-height: 25px;
                        padding-right: 4px;
                    }

                    .style-list {
                        width: 210px;

                        .style-item {
                            display: inline-block;
                            color: #2e4bf2;
                            line-height: 17px;
                            padding: 4px 12px;
                            background: rgba(46, 75, 242, 0.1);
                            border-radius: 2px;
                            margin-right: 6px;
                            margin-bottom: 6px;
                        }
                    }
                }

                .artist {
                    display: flex;
                    margin-top: 8px;

                    .artist-tit {
                        width: 52px;
                        line-height: 25px;
                        padding-right: 4px;
                    }

                    .artist-item {
                        display: inline-block;
                        color: #ff7432;
                        line-height: 17px;
                        padding: 4px 12px;
                        background: rgba(255, 116, 50, 0.1);
                        border-radius: 2px;
                    }
                }

                .li {
                    display: flex;
                    margin-top: 14px;

                    .li-l {
                        width: 68px;
                        padding-right: 8px;
                    }

                    .li-r {
                        color: #333333;
                    }
                }
            }

            .main-t::-webkit-scrollbar {
                /*滚动条整体样式*/
                width: 4px;
                /*高宽分别对应横竖滚动条的尺寸*/
                height: 8px;
            }

            .main-t::-webkit-scrollbar-thumb {
                /*滚动条里面小方块*/
                border-radius: 5px;
                -webkit-box-shadow: inset 0 0 5px #d8d8d8;
                background: #d8d8d8;
            }

            .main-t::-webkit-scrollbar-track {
                /*滚动条里面轨道*/
                -webkit-box-shadow: inset 0 0 5px #f8f8f8;
                border-radius: 5px;
                background: #f8f8f8;
            }

            .btn {
                width: 251px;
                height: 44px;
                background: linear-gradient(90deg, #4875ff 0%, #2b4afe 100%);
                border-radius: 2px;
                font-size: 15px;
                margin-top: 8px;

                i {
                    color: #ffffff;
                    font-size: 16px;
                }
            }
        }

        .btn-box {
            margin-top: 20px;
            display: flex;
            justify-content: flex-end;
        }
    }
}</style>
